import Grid from '@material-ui/core/Grid'
import React from 'react'
import Logo from '../../images/logo.svg'
import { Title } from '../../organisms/CommonEstimateStep'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { H3, InfoSmall } from '../../atoms/Text'
import girlBubble from '../../images/girlbubble.jpg'
import { UserPhoto } from '../../organisms/UserMessages/styled'
import Box from '@material-ui/core/Box'
import { GridSize } from '@material-ui/core/Grid/Grid'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import { FColG16, FExpand, FRow, FRowG16 } from '../../atoms/Layout'
import { Close } from '../../atoms/Buttons/Close'
import DatePickerInPlace from '../../molecules/DatePickerInPlace'
import styled from 'styled-components'
import { sBreakpoints } from '../../constants'
import { columnGap, rowGap } from '../../utils'
import { Moment } from 'moment'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Button, IconButton } from 'gatsby-theme-material-ui'
import EventIcon from '@material-ui/icons/Event'
import Input, { InputProps } from '../../atoms/Input'
import gCalendar from '../../images/gcalendar.jpg'

import { Checkbox, CircularProgress, FormControlLabel, Grow } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ButtonBlack } from '../../atoms/Buttons/Button'
import CheckIcon from '@material-ui/icons/CheckCircle'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import GoogleCalendarCheckbox from '../../molecules/GoogleCalendarCheckbox'
import axios from 'axios'
const LogoRow = styled(FRowG16)`
    align-items: center;

    ${sBreakpoints.tablet} {
        align-items: stretch;
        flex-direction: column;
        ${columnGap(0)}
        ${rowGap(16)}
    }
`
const LargeColumn = styled.div`
    flex-basis: 70%;

    ${sBreakpoints.tablet} {
        flex-basis: 100%;
    }
`

const ShortColumn = styled.div`
    flex-basis: 30%;

    ${sBreakpoints.tablet} {
        flex-basis: 100%;
    }
`

const Wrapper = styled(FColG16)`
    padding: 32px;

    ${sBreakpoints.tablet} {
        padding: 16px;
    }
`
const SInput = styled(Input)`
    width: clamp(0px, 500px, 100%);

    .MuiInputLabel-outlined {
        transform: translate(24px, 24px) scale(1);
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
    }
`

const AddGuestsButton = styled(Button)`
    border-radius: 23.5px;
    border: 2px solid #2e2e2e;
    text-transform: initial;
    padding: 8px 20px;
`

const ScheduleButton = styled(ButtonBlack)`
    font-weight: 400;
`

export type UserData = {
    name: string
    email: string
    phone: string
    sendGCalendarInvitation: boolean
}

export type ScheduleACallProps = {}

export const ScheduleACall = React.memo<ScheduleACallProps>(({}) => {
    const { t } = useTranslation(['schedule-event'])
    const [date, setDate] = React.useState<Moment | null>(null)
    const [showFillInfo, setShowFillInfo] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [emails, setEmails] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState(false)
    const { language } = useI18next()

    const { onTablet } = useRenderBreakpoints()
    const [userData, setUserData] = React.useState<UserData>({
        email: '',
        name: '',
        phone: '',
        sendGCalendarInvitation: false,
    })

    const inputDataProps = (
        key: keyof Omit<UserData, 'sendGCalendarInvitation'>,
    ): Pick<InputProps, 'onChange' | 'value' | 'label' | 'required'> => {
        return {
            required: true,
            label: t(key),
            value: userData[key],
            onChange: (e) => {
                const val = e.target.value
                setUserData((p) => ({
                    ...p,
                    [key]: key === 'phone' ? val.replace(/\D/g, '') : val,
                }))
            },
        }
    }

    const wrap = (titleKey: string, children: React.ReactNode) => {
        return (
            <Box
                width={'100vw'}
                height={'100vh'}
                style={{
                    overflow: 'auto',
                }}
            >
                <Wrapper>
                    <LogoRow>
                        <ShortColumn style={{ position: 'relative' }}>
                            <Logo style={{ maxWidth: 181 }} />
                            {onTablet(
                                () => {
                                    return (
                                        <Link
                                            to={'/'}
                                            style={{
                                                position: 'absolute',
                                                right: 16,
                                                top: 16,
                                            }}
                                        >
                                            <Close />
                                        </Link>
                                    )
                                },
                                { mode: 'render' },
                            )}
                        </ShortColumn>
                        <LargeColumn>
                            <FRow>
                                <H3 style={{ fontWeight: 'bold' }}>{t(titleKey)}</H3>
                                <FExpand />
                                {onTablet(
                                    () => {
                                        return (
                                            <Link to={'/'}>
                                                <Close />
                                            </Link>
                                        )
                                    },
                                    { mode: 'hide' },
                                )}
                            </FRow>
                        </LargeColumn>
                    </LogoRow>
                    <LogoRow style={{ alignItems: 'unset' }}>{children}</LogoRow>
                </Wrapper>
            </Box>
        )
    }

    if (showSuccess) {
        return (
            <Box
                width={'100vw'}
                height={'100vh'}
                style={{
                    overflow: 'auto',
                }}
            >
                <Wrapper>
                    <FColG16 style={{ alignItems: 'center' }}>
                        <FRowG16
                            style={{ justifyContent: 'space-between', width: '100%' }}
                        >
                            <Link
                                to={'/'}
                                style={{
                                    opacity: 0,
                                    pointerEvents: 'none',
                                }}
                            >
                                <Close />
                            </Link>
                            <Logo style={{ maxWidth: 181 }} />
                            <Link to={'/'}>
                                <Close />
                            </Link>
                        </FRowG16>
                        <div>
                            <Grow in={true}>
                                <Box paddingY={7}>
                                    <FColG16 style={{ alignItems: 'center' }}>
                                        <h2
                                            style={{
                                                textAlign: 'center',
                                                fontSize: '2.5rem',
                                                maxWidth: 500,
                                                color: '#2d2d2d',
                                            }}
                                        >
                                            {t('success')}
                                        </h2>
                                        <CheckIcon
                                            style={{
                                                color: '#6ebf6e',
                                                fontSize: 120,
                                            }}
                                        />
                                    </FColG16>
                                </Box>
                            </Grow>
                        </div>
                    </FColG16>
                </Wrapper>
            </Box>
        )
    }

    if (showFillInfo) {
        return wrap(
            'insert-details',
            <React.Fragment>
                <ShortColumn>
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <IconButton
                                style={{
                                    border: '1px solid currentColor',
                                }}
                                onClick={() => {
                                    setShowFillInfo(false)
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <InfoSmall>Paula Cedeño</InfoSmall>

                            <Title
                                style={{
                                    textAlign: 'left',
                                    marginTop: 0,
                                    marginBottom: 0,
                                    fontSize: '1.75rem',
                                }}
                            >
                                {t('15-min-meeting')}
                            </Title>
                        </Grid>
                        <Grid item container spacing={1} alignItems={'center'}>
                            <Grid item>
                                <InfoSmall>
                                    <WatchLaterIcon />
                                </InfoSmall>
                            </Grid>
                            <Grid item>
                                <InfoSmall style={{ paddingBottom: 4 }}>15 min</InfoSmall>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1} alignItems={'center'}>
                            <Grid item>
                                <InfoSmall style={{ color: '#21C7A4' }}>
                                    <EventIcon />
                                </InfoSmall>
                            </Grid>
                            <Grid item>
                                <InfoSmall style={{ paddingBottom: 4, color: '#21C7A4' }}>
                                    {`${date?.format('HH:mm - ')}${date
                                        ?.clone()
                                        .add(15, 'minutes')
                                        .format('HH:mm')}, ${date?.format(
                                        'dddd, MMMM D, YYYY',
                                    )}`}
                                </InfoSmall>
                            </Grid>
                        </Grid>
                    </Grid>
                </ShortColumn>
                <LargeColumn>
                    <form
                        onSubmit={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setLoading(true)
                            axios
                                .post(
                                    process.env.FUNCTIONS_BASE_URL + '/sendScheduleACall',
                                    JSON.stringify({
                                        data: {
                                            ...userData,
                                            emails,
                                            startDate: date?.toISOString(),
                                            endDate: date
                                                ?.clone()
                                                .add(15, 'minutes')
                                                .toISOString(),
                                        },
                                        lang: language,
                                    }),
                                    { headers: { 'Content-Type': 'application/json' } },
                                )
                                .then((re) => {
                                    if (re.status === 200) {
                                        setShowSuccess(true)
                                    }
                                })
                                .finally(() => {
                                    setLoading(false)
                                })
                        }}
                    >
                        <Grid container direction={'column'} spacing={2}>
                            <Grid item>
                                <SInput {...inputDataProps('name')} />
                            </Grid>
                            <Grid item>
                                <SInput type={'email'} {...inputDataProps('email')} />
                            </Grid>
                            {emails.map((e, i) => {
                                return (
                                    <Grid item key={i}>
                                        <SInput
                                            type={'email'}
                                            label={t('email')}
                                            value={e}
                                            onChange={(e) => {
                                                const val = e.target.value

                                                setEmails((p) =>
                                                    p.map((x, j) => {
                                                        if (i === j) return val
                                                        return x
                                                    }),
                                                )
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position={'end'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                setEmails((p) => {
                                                                    return p.filter(
                                                                        (p, j) => i !== j,
                                                                    )
                                                                })
                                                            }}
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                )
                            })}
                            <Grid item>
                                <AddGuestsButton
                                    onClick={() => {
                                        setEmails((p) => [...p, ''])
                                    }}
                                >
                                    {t('add-more-guests')}
                                </AddGuestsButton>
                            </Grid>
                            <Grid item>
                                <SInput type={'tel'} {...inputDataProps('phone')} />
                            </Grid>
                            <Grid item>
                                <GoogleCalendarCheckbox
                                    checked={userData.sendGCalendarInvitation}
                                    onChange={(checked) => {
                                        setUserData((p) => ({
                                            ...p,
                                            sendGCalendarInvitation: checked,
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <ScheduleButton type={'submit'}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            inset: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            opacity: loading ? 1 : 0,
                                        }}
                                    >
                                        <CircularProgress
                                            style={{ color: 'white' }}
                                            size={'1em'}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            opacity: loading ? 0 : 1,
                                        }}
                                    >
                                        {t('schedule-event')}
                                    </div>
                                </ScheduleButton>
                            </Grid>
                        </Grid>
                    </form>
                </LargeColumn>
            </React.Fragment>,
        )
    }

    return wrap(
        'select-date',
        <React.Fragment>
            <ShortColumn>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Title
                            style={{
                                textAlign: 'left',
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: '2.25rem',
                            }}
                        >
                            {t('title')}
                        </Title>
                    </Grid>
                    <Grid
                        container
                        item
                        wrap={'nowrap'}
                        alignItems={'center'}
                        spacing={2}
                    >
                        <Grid item>
                            <UserPhoto
                                url={girlBubble}
                                style={{
                                    fontSize: 64,
                                }}
                            />
                        </Grid>
                        <Grid container item direction={'column'}>
                            <Grid item>
                                <H3
                                    style={{
                                        fontSize: '1rem',
                                        fontWeight: 600,
                                    }}
                                >
                                    Paula Cedeño
                                </H3>
                            </Grid>
                            <Grid item>
                                <InfoSmall>{t('project-manager')}</InfoSmall>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item direction={'column'} spacing={2}>
                        <Grid item>
                            <InfoSmall>{t('schedule-description')}</InfoSmall>
                        </Grid>
                        <Grid item container spacing={1} alignItems={'center'}>
                            <Grid item>
                                <InfoSmall>
                                    <WatchLaterIcon />
                                </InfoSmall>
                            </Grid>
                            <Grid item>
                                <InfoSmall style={{ paddingBottom: 4 }}>
                                    {t('15-min-meeting')}
                                </InfoSmall>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ShortColumn>
            <LargeColumn>
                <DatePickerInPlace
                    onConfirm={(d) => {
                        setDate(d)
                        setShowFillInfo(true)
                    }}
                />
            </LargeColumn>
        </React.Fragment>,
    )
})

ScheduleACall.displayName = 'ScheduleACall'

export default ScheduleACall
