import styled from 'styled-components'
import { FColG16, FRow, FRowG16 } from '../../atoms/Layout'
import { colorMap } from './constants'
import { sBreakpoints } from '../../constants'
import { columnGap, rowGap } from '../../utils'
import { UserMessagesProps } from './index'

export const Expand = styled.div`
    flex: 6;
`
export const UserPhoto = styled(FRow)<{ url: string }>`
    border-radius: 50%;
    font-size: 280px;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    overflow: hidden;
    background-color: rgb(232, 232, 232);
    background-image: url(${(props) => props.url});
    background-size: cover;

    ${sBreakpoints.tablet} {
        font-size: 100px;
    }
`
export const BubbleMessage = styled(FRow)<Pick<UserMessagesProps, 'direction' | 'color'>>`
    border-radius: ${(props) => {
        const bottom = '20px'
        const top = '10px'
        if (props.direction === 'left') return `0 ${top} ${bottom} ${bottom}`
        return `${top} 0 ${bottom} ${bottom}`
    }};
    padding: 24px;
    background-color: ${(props) => colorMap[props.color]};
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 24px;
    color: #2e2e2e;

    ${sBreakpoints.tablet} {
        font-size: 0.875rem;
        padding: 16px;
    }
`
export const MessageWrapper = styled(FColG16)<Pick<UserMessagesProps, 'direction'>>`
    flex: 4;
    align-items: ${(props) =>
        ({ left: 'flex-start', right: 'flex-end' }[props.direction])};
`
export const Root = styled(FRowG16)<Pick<UserMessagesProps, 'direction'>>`
    ${sBreakpoints.tablet} {
        flex-direction: column;
        align-items: ${(props) =>
            props.direction === 'left' ? 'flex-start' : 'flex-end'};

        & ${MessageWrapper} {
            max-width: 70%;
        }
        ${columnGap(0)}
        ${rowGap(16)}
    }
`
