import React from 'react'
import 'react-dates/initialize'
import { DayPickerSingleDateController } from 'react-dates'
import moment, { Moment } from 'moment'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import AutoSizer from 'react-virtualized-auto-sizer'
import 'react-dates/lib/css/_datepicker.css'
import './styles.css'
import { Button as MuiButton, IconButton } from 'gatsby-theme-material-ui'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { FRow } from '../../atoms/Layout'
import { sBreakpoints } from '../../constants'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const getTimes = (date: Moment) => {
    let start = date.clone().set({
        hours: 6,
        minutes: 0,
    })
    const end = date.clone().set({
        hours: 20,
        minutes: 0,
    })
    const times: Moment[] = []
    while (start <= end) {
        times.push(start)
        start = start.clone().add(15, 'minutes')
    }
    return times
}

const Button = styled(MuiButton)<{
    selected?: boolean
}>`
    border-radius: 4px;
    color: #666666;
    background-color: ${(props) =>
        props.selected ? 'rgba(196, 196, 196, 0.5)' : 'white'};
    width: 100%;
    border: 2px solid
        ${(props) => (props.selected ? 'transparent' : 'rgba(102, 102, 102, 0.5)')};
    transition: all 0.3s ease-in-out;
`

const Wrapper = styled(FRow)`
    ${sBreakpoints.tablet} {
        flex-direction: column;
    }
`

const TimeGrid = styled(Grid)`
    width: 228px;
    margin: 0;
    overflow: hidden;

    ${sBreakpoints.tablet} {
        width: 100%;
    }
`

const TimeColumn = styled(Grid)`
    ${sBreakpoints.notTablet} {
        position: absolute;
        inset: 0;
    }
`

const TimeColumnWrapper = styled.div`
    position: relative;
    min-width: 250px;

    ${sBreakpoints.tablet} {
        width: 100%;
    }
`

export type DatePickerInPlaceProps = {
    onConfirm?(date: Moment): void
}

export const DatePickerInPlace = React.memo<DatePickerInPlaceProps>(({ onConfirm }) => {
    const now = React.useMemo(() => moment().startOf('day'), [])
    const [date, setDate] = React.useState<Moment | null>(moment())
    const { t } = useTranslation(['common'])

    const times = React.useMemo(() => {
        if (!date) return []
        return getTimes(date)
    }, [date])
    const [focused, setFocused] = React.useState(false)
    return (
        <Wrapper>
            <div style={{ flex: 1, minWidth: 325 }}>
                <AutoSizer disableHeight={true}>
                    {({ width }) => {
                        return (
                            <div
                                style={{
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    '--container-max-width': `${width}px`,
                                }}
                            >
                                <DayPickerSingleDateController
                                    renderNavPrevButton={(props) => {
                                        return (
                                            <IconButton
                                                {...props}
                                                style={{
                                                    right: 52,
                                                    // top: 20,
                                                    color: '',
                                                    position: 'absolute',
                                                    width: 30,
                                                    height: 30,
                                                }}
                                            >
                                                <KeyboardArrowLeftIcon />
                                            </IconButton>
                                        )
                                    }}
                                    renderNavNextButton={(props) => {
                                        return (
                                            <IconButton
                                                {...props}
                                                style={{
                                                    right: 22,
                                                    // top: 20,
                                                    color: '',
                                                    position: 'absolute',
                                                    width: 30,
                                                    height: 30,
                                                }}
                                            >
                                                <KeyboardArrowRightIcon />
                                            </IconButton>
                                        )
                                    }}
                                    isDayBlocked={(d) => {
                                        return d < now
                                    }}
                                    hideKeyboardShortcutsPanel={true}
                                    initialVisibleMonth={moment}
                                    date={date} // momentPropTypes.momentObj or null
                                    onDateChange={(date) => {
                                        setDate((p) => {
                                            if (p) {
                                                date?.set({
                                                    hours: p.get('hours'),
                                                    minutes: p.get('minutes'),
                                                    seconds: p.get('seconds'),
                                                })
                                            } else {
                                                date?.set({
                                                    hours: 6,
                                                    minutes: 0,
                                                    seconds: 0,
                                                })
                                            }
                                            return date
                                        })
                                    }} // PropTypes.func.isRequired
                                    focused={focused} // PropTypes.bool
                                    onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                                />
                            </div>
                        )
                    }}
                </AutoSizer>
            </div>
            <TimeColumnWrapper>
                <TimeColumn container direction={'column'} wrap={'nowrap'}>
                    <Grid
                        item
                        container
                        alignItems={'center'}
                        style={{
                            minHeight: 35,
                            margin: 0,
                        }}
                        spacing={2}
                    >
                        <Grid item>{date?.format('dddd, MMMM DD')}</Grid>
                    </Grid>
                    <Grid
                        item
                        style={{
                            flex: 1,
                            overflow: 'auto',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                            }}
                        >
                            <TimeGrid container spacing={2} direction={'column'}>
                                {times.map((time, k) => {
                                    const timeFmt = time.format('HH:mm')
                                    const selected = time.valueOf() === date?.valueOf()
                                    return (
                                        <Grid
                                            item
                                            key={timeFmt}
                                            style={{
                                                width: selected ? '100%' : '200%',
                                                transition: 'width 0.3s ease-in-out',
                                            }}
                                        >
                                            <Grid container spacing={1} wrap={'nowrap'}>
                                                <Grid item style={{ width: '100%' }}>
                                                    <Button
                                                        onClick={() => {
                                                            setDate(time)
                                                        }}
                                                        selected={selected}
                                                    >
                                                        {timeFmt}
                                                    </Button>
                                                </Grid>
                                                <Grid item style={{ width: '100%' }}>
                                                    <Button
                                                        onClick={() => {
                                                            if (!date) return
                                                            onConfirm?.(date)
                                                        }}
                                                        selected={selected}
                                                        style={{
                                                            backgroundColor: '#666666',
                                                            color: 'white',
                                                            textTransform: 'initial',
                                                        }}
                                                    >
                                                        {t('confirm')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </TimeGrid>
                        </div>
                    </Grid>
                </TimeColumn>
            </TimeColumnWrapper>
        </Wrapper>
    )
})

DatePickerInPlace.displayName = 'DatePickerInPlace'

export default DatePickerInPlace
