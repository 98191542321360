import React from 'react'
import { graphql } from 'gatsby'
import Header from '../../molecules/Header'
import ScheduleACall from '../../templates/ScheduleACall'

export type ScheduleEventProps = {}

export const ScheduleEvent = React.memo<ScheduleEventProps>(({}) => {
    return (
        <React.Fragment>
            <main>
                <ScheduleACall />
            </main>
        </React.Fragment>
    )
})

ScheduleEvent.displayName = 'ScheduleEvent'

export default ScheduleEvent

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
